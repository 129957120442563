import React, { useEffect, useContext } from "react"
import styled from "styled-components"

import Layout from "../layout"
import { Message } from "../components/l10n"
import illustration from "../images/svg/thanks.svg"
import { CartContext } from "../components/cart"
import { Content, Title } from "../components/styled"

const Image = styled.img`
  margin: 30px;
`

const ThanksPage = () => {
  const { clear } = useContext(CartContext)

  useEffect(() => {
    clear()
  }, [])

  return (
    <Layout noindex>
      <Content>
        <Title>
          <Message id="thanks" />
        </Title>
        <Message id="thanks_text" Tag="p" />
        <Message id="confirmation_email" Tag="p" />
        <Image src={illustration} />
      </Content>
    </Layout>
  )
}

export default ThanksPage
